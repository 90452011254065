<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M0 6C0 4.34297 1.34297 3 3 3H21C22.6547 3 24 4.34297 24 6V18C24 19.6547 22.6547 21 21 21H3C1.34297 21 0 19.6547 0 18V6ZM2.25 6V7.03594L10.3359 13.6734C11.3016 14.4703 12.6984 14.4703 13.6641 13.6734L21.75 7.03594V5.95781C21.75 5.5875 21.4125 5.20781 21 5.20781H3C2.58562 5.20781 2.25 5.5875 2.25 5.95781V6ZM2.25 9.94687V18C2.25 18.4125 2.58562 18.75 3 18.75H21C21.4125 18.75 21.75 18.4125 21.75 18V9.94687L15.0938 15.4125C13.2937 16.8891 10.7063 16.8891 8.86406 15.4125L2.25 9.94687Z"
    />
  </svg>
</template>
